.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: flex-start;
  &.two-title {
    h1 {
      flex: 1;
      &:last-child {
        margin-left: 40px;
      }
    }
  }
  h1 {
    font-size: 21px;
  }

  .btn__box {
    span {
      color: $base-grey;
      margin-right: 15px;
    }

    button {
      font-size: inherit;
      background: $base-color;
      color: $base-white;
      padding: 5px 15px;
      border-radius: 5px;
    }
  }
}
