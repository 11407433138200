.custom-alert {
  display: flex;
  height: auto;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  top: 40px;
  right: 40px;
  z-index: 10;

  &.on {
    opacity: 1;
    visibility: visible;
    transition: all 1s ease-in-out;
  }
  &.off {
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease-in-out;
  }

  .alert__box {
    width: auto;
    max-width: 400px;
    min-width: 300px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: $base-white;
    header {
      padding: 10px 20px;
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: solid 1px $alert-border-color;
      background: black;
      box-sizing: border-box;
      color: $base-white;
      font-size: 18px;
      justify-content: space-between;
      &.error {
        background: $alert-error-color;
      }
      &.warning {
        background: $alert-warning-color;
      }
      &.confirm {
        background: $alert-confirm-color;
      }
      .close__btn {
        cursor: pointer;
        background: url("/images/close_x.png");
        background-size: 16px 16px;
        text-indent: -999em;
        width: 16px;
        height: 16px;
      }
    }
    .message {
      display: flex;
      box-sizing: border-box;
      padding: 20px 20px;
      height: auto;
      background: $table-header-bg;
    }
  }
}
