.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  .form__box {
    width: 100%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    justify-content: space-between;
    label {
      font-size: 18px;
    }
    input {
      color: $base-grey;
      margin-top: 15px;
      height: 40px;
      padding: 0px 10px;
      font-size: inherit;
      border-radius: 5px;
    }

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"] + label {
      &.on {
        background: url("/images/checkbox_on.png");
        background-size: 20px 20px;
      }
      &.off {
        background: url("/images/checkbox_off.png");
        background-size: 20px 20px;
      }
      display: inline-block;
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    &.option {
      min-height: 20px;
      margin-bottom: 40px;
      div {
        display: flex;
        align-items: center;

        span {
          cursor: pointer;
          color: $base-header-color;
        }
      }
    }

    &.action {
      button {
        cursor: pointer;
        background: $base-color;
        height: 45px;
        color: $base-white;
        font-size: inherit;
        border-radius: 5px;
      }
    }

    &.caption {
      color: $base-grey;
      align-items: center;
      min-height: 20px;
      margin-bottom: 0px;
      b {
        font-weight: normal;
      }
    }
  }
}
