.custom-dropdown {
  .Dropdown-root {
    width: 100%;
    height: 100%;

    .Dropdown-control {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 8px 35px 8px 10px;
    }
  }

  .Dropdown-option {
    &.is-selected {
      background: $base-color;
      color: $base-white;
    }
    &:hover {
      background: rgba($base-color, 0.3);
      color: inherit;
    }
  }
}
