//FONT
$base-font-size: 16px;

//Width, Height
$login-layout-width: 800px;
$main-layout-max-width: 100%;
$main-layout-half-max-width: 1600px;

$main-layout-min-width: 1200px;

//1460px 13inch
$tablet-header-width: 120px;
$tablet-header-expand-width: 260px;
