.main-layout {
  width: 100%;
  height: 100%;
  display: flex;
  background: $layout-bg-color;
  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: $main-layout-min-width;
    padding: 40px;
    box-sizing: border-box;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1460px) {
  .main-layout {
    margin-left: $tablet-header-width;
    box-sizing: border-box;
    max-width: calc(100% - 120px);

    .content-wrapper {
      padding: 40px 40px 40px 20px;
    }
  }
}
