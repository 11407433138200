.store-management-page {
  display: flex;
  flex-direction: column;
  max-width: $main-layout-max-width;
  padding-bottom: 20px;

  .option__box {
    .Dropdown-arrow {
      top: 17px;
    }
  }

  .table-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    b {
      color: $base-color;
    }

    .option__box {
      display: flex;
      height: 40px;
      .custom-dropdown {
        width: auto;
        height: 40px;

        .Dropdown-placeholder {
          &::after {
            margin-left: 2px;
            content: "개 씩";
          }
        }
        .Dropdown-option {
          &::after {
            margin-left: 2px;
            content: "개 씩";
          }
        }
      }
    }
  }

  .table__box {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16), 0px 0px 3px rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    overflow: hidden;
    .empty__box {
      width: 100%;
      height: 400px;
      td {
        line-height: 400px;
      }
    }
  }

  .paging__box {
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    background-color: $base-white;
    margin-top: 20px;
    padding: 20px 0px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16), 0px 0px 3px rgba(0, 0, 0, 0.23);
    margin-bottom: 20px;
  }

  .search__box {
    display: flex;
    background-color: $base-white;
    padding: 20px 0px;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
    margin-bottom: 20px;

    .range__box {
      display: flex;
      align-items: center;
      margin: 0px 20px 0px 20px;
      b {
        margin: 0px 10px;
        color: $base-placeholder;
      }
      .react-datepicker-wrapper {
        height: 40px;
        width: 150px;

        .react-datepicker__input-container {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
        }
        input {
          border: solid 1px $table-header-boder-color;
          cursor: pointer;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          text-align: center;
          font-size: 14px;
        }
      }
    }
    .input__box {
      display: flex;
      margin-right: 0px;
      .custom-dropdown {
        width: auto;
        height: 40px;
        margin-right: 10px;
        min-width: 120px;
        &.status {
          min-width: 60px;
          margin-right: 0px;
        }
        .Dropdown-arrow {
          top: 17px;
        }
      }
      input {
        width: 200px;
        box-sizing: border-box;
        padding: 0px 10px;
        font-size: 14px;
        height: inherit;
        box-sizing: border-box;
        border: solid 1px $table-header-boder-color;
        min-height: 40px;
      }
    }

    .button__box {
      display: flex;
      button {
        height: inherit;
        color: $base-white;
        background: $base-color;
        width: 55px;
        min-height: 40px;
        font-size: 14px;
      }
    }

    .guide__box {
      margin-left: 20px;
      span {
        font-size: 14px;
        color: $base-placeholder;
      }
    }
  }
}
