.react-table__container {
  width: 100%;
  table {
    table-layout: fixed;
    width: 100%;
    thead {
      background: $base-white;
      border-bottom: solid 1px $table-header-boder-color;

      tr {
        height: 60px;
        th {
          line-height: 60px;
          color: $base-header-color;
        }
      }
    }
    tbody {
      background: $base-white;
      tr {
        cursor: pointer;
        height: 50px;
        border-bottom: solid 1px $table-border-color;
        &:hover {
          background: $table-header-bg;
        }

        td {
          line-height: 50px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
          div {
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .status-text {
            // 'R': '접수',
            // 'S': '예정',
            // 'H': '보류',
            // 'F': '완료',
            // 'C': '취소'
            color: $base-white;
            padding: 3px 10px;
            border-radius: 5px;
            &.R {
              background: $tabelt-install-status-R;
            }
            &.S {
              background: $tabelt-install-status-S;
            }
            &.H {
              background: $tabelt-install-status-H;
            }
            &.F {
              background: $tabelt-install-status-F;
            }
            &.C {
              background: $tabelt-install-status-C;
            }
          }
          .tabelt__status {
            display: flex;
            position: relative;
            justify-content: center;
            .table__item {
              display: flex;
              align-items: center;
              margin-right: 10px;
              box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 0.4);
              border-radius: 5px;
              height: 30px;
              box-sizing: border-box;
              padding: 0px 10px;
              position: relative;
              opacity: 0.3;

              &.run {
                opacity: 1;
              }

              div {
                margin-right: 5px;
                text-indent: -999em;
                width: 20px;
                height: 20px;
                background-size: 20px 20px;
                background-repeat: no-repeat;
                background-position: center;
                &:last-child {
                  margin-right: 0px;
                }
              }
              .device-off {
                background: url("/images/power_off.png");
                background-size: 20px 20px;
              }
              .device-on {
                background: url("/images/power_on.png");
                background-size: 20px 20px;
              }

              .connected-on {
                background: url("/images/connect_on.png");
                background-size: 20px 20px;
              }
              .connected-off {
                background: url("/images/connect_off.png");
                background-size: 20px 20px;
              }

              &:last-child {
                margin-right: 0px;
              }
            }
          }
          // .install__count {
          //   display: flex;
          //   justify-content: center;

          //   li {
          //     display: flex;
          //     align-items: center;
          //     margin-right: 5px;
          //     &:last-child {
          //       margin-right: 0px;
          //     }

          //     .status {
          //       width: 15px;
          //       height: 15px;
          //       background: $tablet-status-on;
          //       border-radius: 50%;
          //       margin-right: 8px;

          //       &.off {
          //         background: $tablet-status-off;
          //       }

          //       &.empty {
          //         background: $tablet-stuats-hold;
          //       }
          //     }
          //   }
          // }
        }
      }
    }
  }
}
