.store-register-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  .basic-info {
    display: flex;
    max-width: $main-layout-max-width;
    flex-wrap: wrap;
    .form__box {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 760px;
      background: $base-white;
      padding: 40px 60px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
      border-radius: 5px;
      min-width: 760px;
      margin-bottom: 20px;

      box-sizing: border-box;
      &.install {
        margin-left: 40px;
      }
      header {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: solid 1px $base-grey;
      }

      ul {
        $base-input-width: 280px;
        display: flex;
        flex-direction: column;

        li {
          height: auto;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          flex: 1;
          min-height: 40px;

          &.textarea {
            min-height: 150px;
          }
          &.required {
            label {
              &:after {
                content: "필수";
                margin-left: 5px;
                font-size: 10px;
                color: $base-red;
              }
            }
          }
          label {
            font-size: inherit;
            margin-right: 10px;
            min-width: 130px;
            display: flex;
            align-items: center;
          }

          input {
            height: 40px;
            padding: 0px 10px;
            font-size: 14px;
            margin-right: 20px;
            flex: 1;
            max-width: $base-input-width;
            box-sizing: border-box;
            border-radius: 5px;

            &:disabled {
              cursor: not-allowed;
              pointer-events: all !important;
              background: rgba($base-grey, 0.2);
            }
          }

          button.find-address {
            height: 40px;
            width: 80px;
            background: $base-white;
            color: $base-color;
            border: solid 1px $base-color;
            box-sizing: border-box;
            border-radius: 5px;
          }

          textarea {
            width: 460px;
            height: 150px;
            padding: 10px;
            font-size: 14px;
            box-sizing: border-box;
            border-radius: 5px;
          }

          .react-datepicker-wrapper {
            flex: 1;
            max-width: $base-input-width;
            input {
              cursor: pointer;
              width: 100%;
            }
          }

          .Dropdown-control {
            border-radius: 5px;
          }
        }

        .option__box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          margin-bottom: 10px;
          header {
            margin-top: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: none;
            margin-bottom: 0px;
            h4 {
              color: $base-header-color;
              font-size: 16px;
            }
            button {
              font-size: 14px;
              background: $base-color;
              color: $base-white;
              border-radius: 5px;
              margin-left: 10px;
              line-height: 23px;
            }
          }

          li {
            background: rgba($base-grey, 0.13);
            padding: 20px;
            box-sizing: border-box;
            border-radius: 5px;
            position: relative;
            width: 100%;
            min-height: 80px;
            max-width: 760px;
            div {
              flex: 1;
              display: flex;
              align-items: center;

              &.status__box {
                width: 60px;
                flex: none;
                height: 20px;
                justify-content: center;

                div {
                  margin-right: 5px;
                  text-indent: -999em;
                  width: 20px;
                  height: 20px;
                  &:last-child {
                    margin-right: 0px;
                  }
                }
                .device-off {
                  background: url("/images/power_off.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: center;
                }
                .device-on {
                  background: url("/images/power_on.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: center;
                }
                .connected-on {
                  background: url("/images/connect_on.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: center;
                }
                .connected-off {
                  background: url("/images/connect_off.png");
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: center;
                }
              }
            }

            label {
              text-align: right;
              min-width: 80px;
              margin: 0px 15px;
            }
            input {
              margin-right: 10px;
              width: auto;
            }
            .remove__btn {
              cursor: pointer;
              text-indent: -999em;
              width: 16px;
              height: 16px;
              background: url("/images/trashcan.png");
              background-size: 16px 16px;
              opacity: 0.5;
              position: absolute;
              right: -25px;
              top: 30px;
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .button__box {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    max-width: $main-layout-max-width;

    button {
      border-radius: 5px;
      width: 120px;
      height: 50px;
      background: $base-color;
      color: $base-white;
      font-size: inherit;
      margin-right: 20px;
      &:last-child {
        margin-right: none;
      }

      &.delete {
        background: $base-red;
        color: $base-white;
      }

      &.cancel {
        background: $base-white;
        color: $base-grey;
        border: solid 1px $base-grey;
      }
    }
  }
}

@media screen and (max-width: 1920px) {
  .store-register-page {
    .basic-info {
      flex-direction: column;
      .form__box {
        &.install {
          min-height: 300px;
          max-height: 820px;
          display: inline-block;
          flex: none;
          height: auto;
          margin-left: 0px;
        }
        ul {
          .option__box {
            li {
              justify-content: left;
            }
          }
        }
      }
    }

    .button__box {
    }
  }
}
