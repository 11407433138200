.login-layout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $base-font-size;
  .content-wrapper {
    width: $login-layout-width;
  }
}
