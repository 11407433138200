.header-container {
  width: $tablet-header-expand-width;
  min-width: $tablet-header-expand-width;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .header__wrapper {
    background: $base-color;
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 100%;
    .user-info {
      display: flex;
      flex-direction: column;
      height: 200px;
      box-sizing: border-box;
      margin-bottom: 20px;
      border-bottom: solid 1px rgba($base-white, 0.3);

      .logo__box {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        .ico-logo {
          margin-top: 30px;
          width: 125px;
          height: 37px;
          background: url("/images/logo.png");
          background-size: 125px 37px;
        }
        h1 {
          margin-top: 10px;
          font-size: 24px;
          letter-spacing: 2px;
          color: $base-white;
        }
      }

      .user__box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        flex: 1;
        span {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $base-white;
          font-size: 18px;
        }

        .logout__btn {
          background: transparent;
          color: $base-white;
          text-indent: -999em;
          width: 18px;
          height: 18px;
          background: url("/images/logout.png");
          background-size: 18px 18px;
          margin-left: 10px;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .nav-list {
      display: flex;
      flex-direction: column;
      padding: 0px 0px 0px 20px;
      flex: 1;
      li {
        height: 60px;
        display: flex;
        align-items: center;
        color: $base-white;
        font-size: 18px;
        position: relative;
        a {
          padding-left: 30px;
          display: flex;
          align-items: center;
          div {
            width: 24px;
            height: 24px;
            margin-right: 10px;
            cursor: pointer;
            &.dashboard {
              background: url("/images/dashboard.png");
              background-size: 24px 24px;
            }
            &.store {
              background: url("/images/pharm.png");
              background-size: 24px 24px;
            }
            &.register {
              background: url("/images/register.png");
              background-size: 24px 24px;
            }
          }
          label {
            cursor: pointer;
          }
        }

        &.active {
          color: $base-color;
          background: $layout-bg-color;
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
          font-weight: bold;
          &:after {
            content: "1";
            background: url("/images/header_item_bg.png");
            background-size: 8px 76px;
            width: 8px;
            height: 76px;
            text-indent: -999em;
            position: absolute;
            right: 0;
            top: -8px;
          }
          div {
            &.dashboard {
              background: url("/images/dashboard_on.png");
              background-size: 24px 24px;
            }
            &.store {
              background: url("/images/pharm_on.png");
              background-size: 24px 24px;
            }
            &.register {
              background: url("/images/register_on.png");
              background-size: 24px 24px;
            }
          }
        }
      }
    }

    .footer-container {
      display: flex;
      flex-direction: column;
      padding-bottom: 40px;
      padding-left: 20px;
      span {
        font-size: 14px;
        color: $base-white;
        margin-bottom: 20px;
      }
    }

    .expand__box {
      display: none;
    }
  }
}

@media screen and (max-width: 1460px) {
  .header-container {
    width: $tablet-header-width;
    min-width: $tablet-header-width;
    position: absolute;
    left: 0px;
    top: 0px;
    background: $layout-bg-color;
    padding: 0px 20px 0px 0px;
    box-sizing: border-box;
    background: $layout-bg-color;

    .header__wrapper {
      .user-info {
        height: 100px;
        .logo__box {
          .ico-logo {
            width: 40px;
            height: 40px;
            background: url("/images/logo_small.png");
            background-size: 40px 40px;
          }
          h1 {
            display: none;
          }
        }

        .user__box {
          display: none;
        }
      }

      .nav-list {
        li {
          justify-content: flex-start;
          padding-left: 20px;
          &.active {
          }

          a {
            padding: 0px;
            justify-content: center;
            div {
              margin-right: 0px;
            }
            label {
              display: none;
            }
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      .footer-container {
        display: none;
      }

      .expand__box {
        position: absolute;
        left: 80px;
        bottom: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: transparent;

        &:after {
          position: absolute;
          content: "1";
          text-indent: -999em;

          width: 21px;
          height: 66px;
          background: url("/images/header_expand.png");
          background-size: 21px 66px;
          right: 0px;
          top: -12px;
        }

        button {
          z-index: 10;
          width: 24px;
          height: 24px;
          background: url("/images/expand.png");
          background-size: 24px 24px;
          text-indent: -999em;
        }

        &.open {
          button {
            transform: rotate(-180deg);
          }
        }
      }
    }

    //expand status
    &.expand {
      width: 100%;
      position: fixed;
      display: flex;
      flex-direction: row;
      background: transparent;
      z-index: 10;
      padding: 0px;
      .header__wrapper {
        width: $tablet-header-expand-width;
        min-width: $tablet-header-expand-width;

        .user-info {
          height: 200px;

          h1 {
            display: flex;
          }

          .user__box {
            display: flex;
          }
        }

        .nav-list {
          padding: 0px 0px 0px 20px;
          li {
            justify-content: flex-start;
            padding-left: 30px;
            a {
              div {
                margin-right: 10px;
              }
              label {
                display: flex;
              }
            }
            &.active {
            }
          }
        }

        .expand__box {
          left: 240px;
        }
        .footer-container {
          display: flex;
        }
      }
      .dim__wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
