.custom-paging {
  display: flex;
  width: 100%;
  .pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    li {
      display: flex;
      justify-content: center;
      height: 30px;
      align-items: center;
      width: 24px;
      height: 24px;
      margin: 0px 5px;
      &.active {
        color: $base-color;
        background: $base-white;
        border: solid 1px $base-color;
      }
    }
  }
}
