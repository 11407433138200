.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 0 0 5px 1px lightgrey;

  .logo__container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $base-color;
    color: $base-white;
    flex-direction: column;
    .ico-logo {
      width: 125px;
      height: 37px;
      background: url("/images/logo.png");
      background-size: 125px 37px;
    }
    h1 {
      font-size: 26px;
      margin-top: 10px;
    }
  }

  .form__container {
    flex: 1;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
  }
}
