$base-color: #2cc2bc;
$base-white: #ffffff;
$base-grey: #abb1b1;
$base-red: rgb(241, 88, 88);
$base-header-color: #545454;
$layout-bg-color: #f5f5f5;
$base-placeholder: #acacac;
//table
$table-header-bg: #effafa;
$table-border-color: #e7ecef;
$table-header-boder-color: #d4d9dc;

//alert
$alert-border-color: #dfdfdf;
$alert-error-color: rgb(240, 87, 87);
$alert-warning-color: rgb(240, 179, 87);
$alert-confirm-color: #2cc2bc;

// tablet
$tablet-status-on: #27e927;
$tablet-status-off: #eb6758;
$tablet-stuats-hold: #cccccc;

// 'R': '접수',
// 'S': '예정',
// 'H': '보류',
// 'F': '완료',
// 'C': '취소'
$tabelt-install-status-R: #f4d425;
$tabelt-install-status-S: #3132fd;
$tabelt-install-status-H: #e55151;
$tabelt-install-status-F: #36b401;
$tabelt-install-status-C: #e55151;
