.dashBoard-page {
  display: flex;
  flex-direction: column;
  max-width: $main-layout-max-width;
  padding-bottom: 20px;
  .chart__container {
    display: flex;

    .chart__box {
      flex: 1;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
      border-radius: 5px;
      overflow: hidden;
      max-height: 720px;
      header {
        height: 60px;
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;
        align-items: center;
        background: $base-white;
        border-bottom: solid 1px $table-header-boder-color;

        h1 {
          font-size: 18px;
          color: $base-header-color;
        }

        .date__box {
          display: flex;
          align-items: center;
          height: inherit;
          .react-datepicker__input-container {
            height: 40px;

            input {
              cursor: pointer;
              height: 100%;
              font-size: 15px;
              padding: 0px 10px;
              box-sizing: border-box;
              width: 100px;
              border-radius: 5px;
              text-align: center;
              border-color: $table-border-color;
            }
          }

          span {
            margin: 0px 10px;
            height: 40px;
            line-height: 40px;
          }

          .search__btn {
            height: 40px;
            padding: 0px 15px;
            font-size: inherit;
            color: $base-white;
            background: $base-color;
            display: flex;
            border-radius: 5px;
            margin-left: 10px;
            line-height: 40px;
          }
        }
      }

      .custom-chart {
        background: $base-white;
        padding: 30px 0px;
      }
    }

    .table__box {
      background: $base-white;
      flex: 1;
      margin-left: 40px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
      border-radius: 5px;
      overflow: auto;
      max-height: 720px;

      .react-table__container {
        overflow: hidden;
        // table {
        //   border: none;
        //   display: flex;
        //   flex-direction: column;
        //   overflow: hidden;
        //   height: 700px;
        //   thead {
        //     width: 100%;
        //     display: flex;
        //     tr {
        //       width: inherit;
        //       display: flex;
        //       th {
        //         flex: 1;
        //       }
        //     }
        //   }

        //   tbody {
        //     display: flex;
        //     flex-direction: column;
        //     overflow-y: auto;
        //     height: 100%;
        //     tr {
        //       width: 100%;
        //       display: flex;
        //       td {
        //         flex: 1;
        //         padding: 0px 10px;
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .dashBoard-page {
    .chart__container {
      flex-direction: column;
      .chart__box {
        min-height: 720px;
      }
      .table__box {
        min-height: 720px;
        margin-left: 0px;
        margin-top: 40px;
      }
    }
  }
}
